<template>
  <div class="c-app">
    <Sidebar v-if="$root.$data.me != null" />
    <CWrapper>
      <Header v-if="$root.$data.me != null" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition-group name="fade">
              <router-view key="1" />
            </transition-group>
          </CContainer>
        </main>
        <Footer />
      </div>
    </CWrapper>

    <notifications 
      class="ce-notification"
      position="top center"
    />
  </div>
</template>

<script>
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'TheContainer',
  components: {
    Sidebar,
    Header,
    Footer
  }
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
