<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      v-if="false"
      class="mx-auto d-lg-none"
      src=""
      width="190"
      height="46"
      alt="The Source Logo"
    />
    <CHeaderNav class="d-sm-down-none mr-auto">
    </CHeaderNav>
    <CHeaderNav>
      <div v-if="$root.$data.me" class="mr-5">{{ $root.$data.me.FullName }} ({{ $root.$data.me.Username }})</div>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>

export default {
  name: 'Header'
}
</script>
