<script>
export default {
  name: 'nav',
  computed: {
    sidebarItems () {
      const options = [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/',
          icon: 'cil-speedometer',
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Royalties']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Accounting',
          to: '/royalty-accounting',
          icon: 'cil-dollar',
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Other']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Uitloggen',
          to: '/logout',
          icon: 'cil-account-logout'
        },
      ]

      return [{
        _name: 'CSidebarNav',
        _children: options.filter(o => { return o.show !== false })
      }]
    }
  }
}
</script>
